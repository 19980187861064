.footer-section {
  &__inner-1 {
    background-color: #f8ecf6;
    padding: 50px 0;

    @include respond-below(sm) {
      padding: 30px 0;
    }

    &__download-btns {
      margin-top: 40px;

      @include respond-below(sm) {
        margin-top: 20px;
      }

      a {
        margin-bottom: 15px;
        padding: 0 !important;
      }
    }

    &__footer-menu {
      h4 {
        font-weight: 400 !important;
        font-size: 15px !important;
      }

      &__wrapper {
        @include respond-below(sm) {
          margin-top: 20px;
        }
      }

      ul {
        margin-top: 30px;
        margin-bottom: 0;

        @include respond-below(sm) {
          margin-top: 10px;
        }

        &.list-no-heading {
          margin-top: 48.5px;

          @include respond-below(sm) {
            margin-top: 28.5px;
          }
        }

        li {
          margin-bottom: 15px;

          a {
            font-family: 'Poppins', sans-serif;
            font-size: 14px;
          }
        }
      }
    }
  }

  &__inner-2 {
    background-color: $lightBgColor;
    padding: 15px 0;

    .row {
      align-items: center;
    }

    &__footer-social {
      display: flex;
      gap: 15px;

      @include respond-below(sm) {
        justify-content: center;
      }

      a {
        display: inline-block;

        svg {
          @include respond-below(sm) {
            width: 40px;
          }

          rect,
          path {
            transition: all 0.2s ease;
          }
        }

        &:hover {
          svg rect {
            fill: $secondaryColor;
          }

          svg path {
            fill: #fff;
          }
        }
      }
    }

    p {
      margin-bottom: 0;
      font-family: 'Poppins', sans-serif;
      font-size: 14px;
      text-align: right;

      @include respond-below(sm) {
        text-align: center;
        margin-top: 10px;
        font-size: 11px;
      }
    }
  }
}

#footer {
  .logo {
    width: 192px;
    object-fit: cover;
    aspect-ratio: 20/8;
  }
}
