@import './variable';
@import './header';
@import './footer';

.fl-page {
  overflow: hidden;
}

.btn {
  box-shadow: none !important;
  outline: none !important;
}

header {
  z-index: 5;
  position: relative;
}

* {
  font-family: 'Roboto', sans-serif;
  padding: 0;
  color: $textColor;
}

html {
  scroll-behavior: auto !important;
}

img {
  max-width: 100%;
  height: auto;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -o-user-select: none;
  user-select: none;
}

input:focus {
  border-color: #f79837 !important;
  box-shadow: none !important;
}

input:read-only:focus {
  border-color: #efe1ed !important;
}

.fl-page {
  overflow: hidden;
}

::selection {
  background: $primaryColor;
  color: #fff;
}

.container {
  padding: 0 20px !important;
  max-width: 1150px !important;

  @include respond-below(sm) {
    // max-width: 425px !important;
  }
}

.input-group-text {
  border: none;
}

.btn-custom {
  background: $primaryColor;
  color: #fff;
  padding-top: 12px;
  padding-bottom: 12px;
  padding-left: 35px;
  padding-right: 35px;
  border: 1px solid $primaryColor;
  font-family: 'Poppins', sans-serif;
  outline: none;
  border-radius: 5px;
  transition: all 0.2s ease;
  display: inline-block;

  &:hover {
    background: #fff;
    color: $primaryColor;
  }
}

.btn-custom-reverse {
  background-color: transparent;
  color: #827c8d;

  &:hover {
    background: $primaryColor;
    color: #fff;
  }
}

p {
  font-size: 16px;
  line-height: 1.8;
  font-weight: 400;
}

h1,
h2,
h3,
h4,
h5 {
  color: $headingColor;
  font-family: 'Poppins', sans-serif !important;
}

h1 {
  font-size: 42px !important;
  font-weight: 600;

  @include respond-below(sm) {
    font-size: 32px !important;
  }

  &.h1-32 {
    font-size: 32px !important;

    @include respond-below(sm) {
      font-size: 24px !important;
    }
  }
}

h2 {
  font-size: 42px;
  font-weight: 600 !important;

  @include respond-below(sm) {
    font-size: 32px !important;
  }

  &.h2-32 {
    font-size: 32px !important;

    @include respond-below(sm) {
      font-size: 24px !important;
    }
  }
}

h3 {
  font-size: 22px;
  font-weight: 600 !important;

  @include respond-below(sm) {
    font-size: 20px !important;
  }
}

h4 {
  font-size: 16px;
  font-weight: 600 !important;

  @include respond-below(sm) {
    font-size: 18px !important;
  }
}

a {
  transition: all 0.2s ease;
  text-decoration: none !important;
  color: #827c8d;
}

a:hover {
  color: $primaryColor;
}

li {
  list-style: none !important;
}

.text-right {
  text-align: right;
}

.text-center {
  text-align: center;
}

.form-control {
  border-radius: 5px;
  border: 1px solid #efe1ed;
  padding: 10px 12px;
}

.form-group-custom {
  margin-bottom: 12px;
}

.faq-section {
  ol {
    counter-reset: faq;

    li {
      margin-block-start: 10px;
      counter-increment: faq;
      position: relative;

      &::before {
        content: counter(faq, decimal) ') ';
        position: absolute;
        text-align: right;
        left: -2rem;
        width: 2rem;
        padding-inline-end: 0.5em;
      }

      &::first-line {
        font-weight: bold;
      }
    }
  }
}

#footer {
  .legals {
    p {
      display: flex;
      justify-content: space-between;
      gap: 0.5em;

      @include respond-below(sm) {
        justify-content: center;
      }
    }
  }
}

a.btn-custom {
  white-space: nowrap;
}
