$root-element: '.header-menu';

#{$root-element} {
  &__burger {
    display: flex;
    justify-content: flex-end;

    svg {
      width: 30px;
      height: 30px;

      path {
        fill: #fff;
      }
    }

    @include respond-above(sm) {
      display: none;
    }
  }

  &__wrapper {
    @include respond-below(sm) {
      position: fixed;
      top: 0;
      left: 0;
      background: #f79837;
      display: flex;
      align-items: center;
      justify-content: center;
      z-index: 555;
      width: 100%;
      height: 100%;
      opacity: 0;
      pointer-events: none;
      transform: translateY(-100%);
      transition: all 0.5s ease;
    }

    &.active {
      transform: none;
      opacity: 1;
      pointer-events: all;
    }

    &__list {
      display: flex;
      align-items: center;
      gap: 40px;
      justify-content: flex-end;
      margin-bottom: 0;
      padding-left: 0;

      @include respond-below(sm) {
        flex-direction: column;
      }

      li:last-of-type:not(.user-item) {
        > a {
          padding: 9px 45px;
          border-radius: 5px;
          border: 1px solid #fff;
        }
      }

      li.user-item {
        position: relative;

        > a {
          display: flex;
          align-items: center;
          min-width: 160px;
          gap: 15px;
          color: #fff !important;
          background-color: $primaryColor;
          padding: 7px 30px 7px 12px;
          border-radius: 5px;
          background-image: url('/frontend/images/icons/arrow-bottom.svg');
          background-repeat: no-repeat;
          background-position: calc(100% - 15px) 50%;

          @include respond-below(sm) {
            min-width: 200px;
          }

          img {
            width: 25px;
            height: 25px;
            object-fit: cover;
          }

          &:hover + .dropdown-user-item {
            opacity: 1;
            pointer-events: all;
          }
        }
        .dropdown-user-item {
          opacity: 0;
          pointer-events: none;
          position: absolute;
          left: 0;
          width: 100%;
          top: 100%;
          display: flex;
          flex-direction: column;
          margin-top: -5px;
          border-bottom-left-radius: 5px;
          border-bottom-right-radius: 5px;
          overflow: hidden;

          &:hover {
            opacity: 1;
            pointer-events: all;
          }

          a {
            padding: 5px 0;
            text-align: center;
            background-color: $primaryColor;

            &:hover {
              background-color: #ec7700;
              color: $secondaryColor;
            }
          }
        }
      }

      a {
        color: #fff;
        font-family: 'Poppins', sans-serif;
        font-size: 14px;
        font-weight: 400;
        display: inline-block;

        &:hover {
          color: #fff;
        }

        @include respond-below(sm) {
          font-size: 24px;
        }
      }

      &__active {
        font-weight: 600 !important;
      }
    }

    &__close {
      position: fixed;
      top: 22px;
      right: 17px;
      color: #fff;

      @include respond-above(sm) {
        display: none;
      }
    }
  }
}

#header {
  .container {
    &::before {
      content: ' ';
      width: 200px;
      height: 100px;
      top: -10px;
      position: absolute;
      background-image: url(/frontend/images/logos/babelli-kurse-white.svg);
      background-position: center center;
      background-size: cover;
      z-index: -1;
    }

    .first-section__logo {
      display: block;
    }
  }
}

.header-static {
  background-color: $secondaryColor;
  padding: 20px 0;
}
