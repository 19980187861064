.header-menu__burger {
  display: flex;
  justify-content: flex-end;
}
.header-menu__burger svg {
  width: 30px;
  height: 30px;
}
.header-menu__burger svg path {
  fill: #fff;
}
@media (min-width: 768px) {
  .header-menu__burger {
    display: none;
  }
}
@media (max-width: 767px) {
  .header-menu__wrapper {
    position: fixed;
    top: 0;
    left: 0;
    background: #f79837;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 555;
    width: 100%;
    height: 100%;
    opacity: 0;
    pointer-events: none;
    transform: translateY(-100%);
    transition: all 0.5s ease;
  }
}
.header-menu__wrapper.active {
  transform: none;
  opacity: 1;
  pointer-events: all;
}
.header-menu__wrapper__list {
  display: flex;
  align-items: center;
  gap: 40px;
  justify-content: flex-end;
  margin-bottom: 0;
  padding-left: 0;
}
@media (max-width: 767px) {
  .header-menu__wrapper__list {
    flex-direction: column;
  }
}
.header-menu__wrapper__list li:last-of-type:not(.user-item) > a {
  padding: 9px 45px;
  border-radius: 5px;
  border: 1px solid #fff;
}
.header-menu__wrapper__list li.user-item {
  position: relative;
}
.header-menu__wrapper__list li.user-item > a {
  display: flex;
  align-items: center;
  min-width: 160px;
  gap: 15px;
  color: #fff !important;
  background-color: #f79837;
  padding: 7px 30px 7px 12px;
  border-radius: 5px;
  background-image: url("/frontend/images/icons/arrow-bottom.svg");
  background-repeat: no-repeat;
  background-position: calc(100% - 15px) 50%;
}
@media (max-width: 767px) {
  .header-menu__wrapper__list li.user-item > a {
    min-width: 200px;
  }
}
.header-menu__wrapper__list li.user-item > a img {
  width: 25px;
  height: 25px;
  object-fit: cover;
}
.header-menu__wrapper__list li.user-item > a:hover + .dropdown-user-item {
  opacity: 1;
  pointer-events: all;
}
.header-menu__wrapper__list li.user-item .dropdown-user-item {
  opacity: 0;
  pointer-events: none;
  position: absolute;
  left: 0;
  width: 100%;
  top: 100%;
  display: flex;
  flex-direction: column;
  margin-top: -5px;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  overflow: hidden;
}
.header-menu__wrapper__list li.user-item .dropdown-user-item:hover {
  opacity: 1;
  pointer-events: all;
}
.header-menu__wrapper__list li.user-item .dropdown-user-item a {
  padding: 5px 0;
  text-align: center;
  background-color: #f79837;
}
.header-menu__wrapper__list li.user-item .dropdown-user-item a:hover {
  background-color: #ec7700;
  color: #732f6f;
}
.header-menu__wrapper__list a {
  color: #fff;
  font-family: "Poppins", sans-serif;
  font-size: 14px;
  font-weight: 400;
  display: inline-block;
}
.header-menu__wrapper__list a:hover {
  color: #fff;
}
@media (max-width: 767px) {
  .header-menu__wrapper__list a {
    font-size: 24px;
  }
}
.header-menu__wrapper__list__active {
  font-weight: 600 !important;
}
.header-menu__wrapper__close {
  position: fixed;
  top: 22px;
  right: 17px;
  color: #fff;
}
@media (min-width: 768px) {
  .header-menu__wrapper__close {
    display: none;
  }
}

#header .container::before {
  content: " ";
  width: 200px;
  height: 100px;
  top: -10px;
  position: absolute;
  background-image: url(/frontend/images/logos/babelli-kurse-white.svg);
  background-position: center center;
  background-size: cover;
  z-index: -1;
}
#header .container .first-section__logo {
  display: block;
}

.header-static {
  background-color: #732f6f;
  padding: 20px 0;
}

.footer-section__inner-1 {
  background-color: #f8ecf6;
  padding: 50px 0;
}
@media (max-width: 767px) {
  .footer-section__inner-1 {
    padding: 30px 0;
  }
}
.footer-section__inner-1__download-btns {
  margin-top: 40px;
}
@media (max-width: 767px) {
  .footer-section__inner-1__download-btns {
    margin-top: 20px;
  }
}
.footer-section__inner-1__download-btns a {
  margin-bottom: 15px;
  padding: 0 !important;
}
.footer-section__inner-1__footer-menu h4 {
  font-weight: 400 !important;
  font-size: 15px !important;
}
@media (max-width: 767px) {
  .footer-section__inner-1__footer-menu__wrapper {
    margin-top: 20px;
  }
}
.footer-section__inner-1__footer-menu ul {
  margin-top: 30px;
  margin-bottom: 0;
}
@media (max-width: 767px) {
  .footer-section__inner-1__footer-menu ul {
    margin-top: 10px;
  }
}
.footer-section__inner-1__footer-menu ul.list-no-heading {
  margin-top: 48.5px;
}
@media (max-width: 767px) {
  .footer-section__inner-1__footer-menu ul.list-no-heading {
    margin-top: 28.5px;
  }
}
.footer-section__inner-1__footer-menu ul li {
  margin-bottom: 15px;
}
.footer-section__inner-1__footer-menu ul li a {
  font-family: "Poppins", sans-serif;
  font-size: 14px;
}
.footer-section__inner-2 {
  background-color: #fef7fd;
  padding: 15px 0;
}
.footer-section__inner-2 .row {
  align-items: center;
}
.footer-section__inner-2__footer-social {
  display: flex;
  gap: 15px;
}
@media (max-width: 767px) {
  .footer-section__inner-2__footer-social {
    justify-content: center;
  }
}
.footer-section__inner-2__footer-social a {
  display: inline-block;
}
@media (max-width: 767px) {
  .footer-section__inner-2__footer-social a svg {
    width: 40px;
  }
}
.footer-section__inner-2__footer-social a svg rect,
.footer-section__inner-2__footer-social a svg path {
  transition: all 0.2s ease;
}
.footer-section__inner-2__footer-social a:hover svg rect {
  fill: #732f6f;
}
.footer-section__inner-2__footer-social a:hover svg path {
  fill: #fff;
}
.footer-section__inner-2 p {
  margin-bottom: 0;
  font-family: "Poppins", sans-serif;
  font-size: 14px;
  text-align: right;
}
@media (max-width: 767px) {
  .footer-section__inner-2 p {
    text-align: center;
    margin-top: 10px;
    font-size: 11px;
  }
}

#footer .logo {
  width: 192px;
  object-fit: cover;
  aspect-ratio: 20/8;
}

.fl-page {
  overflow: hidden;
}

.btn {
  box-shadow: none !important;
  outline: none !important;
}

header {
  z-index: 5;
  position: relative;
}

* {
  font-family: "Roboto", sans-serif;
  padding: 0;
  color: #827c8d;
}

html {
  scroll-behavior: auto !important;
}

img {
  max-width: 100%;
  height: auto;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -o-user-select: none;
  user-select: none;
}

input:focus {
  border-color: #f79837 !important;
  box-shadow: none !important;
}

input:read-only:focus {
  border-color: #efe1ed !important;
}

.fl-page {
  overflow: hidden;
}

::selection {
  background: #f79837;
  color: #fff;
}

.container {
  padding: 0 20px !important;
  max-width: 1150px !important;
}
.input-group-text {
  border: none;
}

.btn-custom {
  background: #f79837;
  color: #fff;
  padding-top: 12px;
  padding-bottom: 12px;
  padding-left: 35px;
  padding-right: 35px;
  border: 1px solid #f79837;
  font-family: "Poppins", sans-serif;
  outline: none;
  border-radius: 5px;
  transition: all 0.2s ease;
  display: inline-block;
}
.btn-custom:hover {
  background: #fff;
  color: #f79837;
}

.btn-custom-reverse {
  background-color: transparent;
  color: #827c8d;
}
.btn-custom-reverse:hover {
  background: #f79837;
  color: #fff;
}

p {
  font-size: 16px;
  line-height: 1.8;
  font-weight: 400;
}

h1,
h2,
h3,
h4,
h5 {
  color: #3e1844;
  font-family: "Poppins", sans-serif !important;
}

h1 {
  font-size: 42px !important;
  font-weight: 600;
}
@media (max-width: 767px) {
  h1 {
    font-size: 32px !important;
  }
}
h1.h1-32 {
  font-size: 32px !important;
}
@media (max-width: 767px) {
  h1.h1-32 {
    font-size: 24px !important;
  }
}

h2 {
  font-size: 42px;
  font-weight: 600 !important;
}
@media (max-width: 767px) {
  h2 {
    font-size: 32px !important;
  }
}
h2.h2-32 {
  font-size: 32px !important;
}
@media (max-width: 767px) {
  h2.h2-32 {
    font-size: 24px !important;
  }
}

h3 {
  font-size: 22px;
  font-weight: 600 !important;
}
@media (max-width: 767px) {
  h3 {
    font-size: 20px !important;
  }
}

h4 {
  font-size: 16px;
  font-weight: 600 !important;
}
@media (max-width: 767px) {
  h4 {
    font-size: 18px !important;
  }
}

a {
  transition: all 0.2s ease;
  text-decoration: none !important;
  color: #827c8d;
}

a:hover {
  color: #f79837;
}

li {
  list-style: none !important;
}

.text-right {
  text-align: right;
}

.text-center {
  text-align: center;
}

.form-control {
  border-radius: 5px;
  border: 1px solid #efe1ed;
  padding: 10px 12px;
}

.form-group-custom {
  margin-bottom: 12px;
}

.faq-section ol {
  counter-reset: faq;
}
.faq-section ol li {
  margin-block-start: 10px;
  counter-increment: faq;
  position: relative;
}
.faq-section ol li::before {
  content: counter(faq, decimal) ") ";
  position: absolute;
  text-align: right;
  left: -2rem;
  width: 2rem;
  padding-inline-end: 0.5em;
}
.faq-section ol li::first-line {
  font-weight: bold;
}

#footer .legals p {
  display: flex;
  justify-content: space-between;
  gap: 0.5em;
}
@media (max-width: 767px) {
  #footer .legals p {
    justify-content: center;
  }
}

a.btn-custom {
  white-space: nowrap;
}